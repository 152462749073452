import React, { useState } from 'react';
import { THEMES } from "../context/withApp";
import Page from "../components/Page/Page";
import SEO from "../components/SEO/SEO";
import Grid, { Col } from '../components/UI/Grid/Grid';
import { Form, InputField, SelectList, TextArea, SubmitButton } from '../components/UI/FormElements/FormElements';
// import PropTypes from 'prop-types';

import type from '../styles/typography.module.scss';

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
};

const Contact = ( props ) => {
    const [ isSubmitted, setSubmitted ] = useState( false );

    function handleSubmit( e ){
        e.preventDefault();
        const data = {};
        
        new FormData(e.target).forEach((value, key) => { data[key] = value });

        fetch( e.target.action , {
            method: 'POST',
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode( data )
        })
        .then( response => {
            if ( response.status !== 200 && response.status !== 201 ) {
                console.error(`Looks like there was a problem. Status Code: ${response.status}`);
                return;
            }

            setSubmitted( true );
        })
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });
    }

    return (
        <Page { ...props.page } theme={ THEMES.light }>
            <SEO title="Contact" keywords={[`digital`, `design`, `front-end development`, `freelance`, `portfolio`, `southampton`, `hampshire`]} setPageTitle={true}/>
            
            <h1>Let&#8217;s work together.</h1>
            
            <p className={type.lead}>
                Send me the details of your project, and I’ll get back to you pronto. Alternatively, give me a call on <a href="tel:+447737610400"><strong>+44 (0)7737 610400</strong></a>.
            </p>

            { !isSubmitted ?
                <Form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={ handleSubmit }>
                    <Grid>
                        <Col>
                            <InputField name="name" type="text" label="Full name" isRequired/>
                        </Col>
                        <Col>
                            <InputField name="email" type="email" label="Email" isRequired/>
                        </Col>
                        <Col>
                            <SelectList name="budget" label="Budget" data={[
                                { name: '£1,000 - £5,000' },
                                { name: '£10,000 - £15,000' },
                                { name: '£15,000 - £20,000' },
                                { name: '£20,000+' }
                            ]}/>
                        </Col>
                        <Col>
                            <SelectList name="timescale" label="Timescale" data={[
                                { name: '1 months' },
                                { name: '1 - 3 months' },
                                { name: '3+ months' }
                            ]}/>
                        </Col>

                        <Col span={2}>
                            <TextArea name="message" label="Tell me briefly about your project" rows={8} isRequired />
                        </Col>
                    </Grid>                    

                    <input name="bot-field" type="hidden"/>
                    <input name="form-name" type="hidden" value="contact"/>

                    <SubmitButton>Send message</SubmitButton>
                </Form>

                :

                <p className={type.lead}>
                    <span role="img" aria-label="Dance">🕺</span> Thank you for your message, I will aim to be in touch within 1 business day.
                </p>
            }
            

            <aside className={type.smallprint}>
                Pete Luffman Consulting Limited is a company registered in England and Wales. Registered number: 09224728.  Registered office: 25 Upper New Road, West End, Southampton, Hampshire SO30 3BD.
            </aside>
        </Page>
    )
}

// Contact.propTypes = {
    
// };

export default Contact;