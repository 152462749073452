import React, { useState } from 'react';
import styles from './FormElements.module.scss';

const Form = ({ children, ...otherProps }) => (
    <form className={styles.form} {...otherProps}>
        {children}
    </form>
);

const InputField = ({ label, name, type, isRequired }) => {
    const [ hasFocus, setFocus ] = useState( false );
    const [ value, setValue ] = useState( '' );
    const labelClass = hasFocus || value !== '' ? styles.labelFocus : styles.label;

    return (
        <div className={styles.row}>
            <label className={ labelClass } htmlFor={name}>
                {label} {!isRequired ? '(optional)' : ''}
            </label>
            <input 
                className={styles.input} 
                id={name} 
                name={name}
                type={type} 
                required={isRequired} 
                onChange={ e => setValue( e.target.value ) }
                onFocus={ e => setFocus( true ) }
                onBlur={ e => setFocus( false ) }
                value={ value }
            />
        </div>  
    )
};


const SelectList = ({ label, name, data, isRequired }) => {
    const [ hasFocus, setFocus ] = useState( false );
    const [ value, setValue ] = useState( '' );
    const labelClass = hasFocus || value !== '' ? styles.labelFocus : styles.label;

    return (
        <div className={styles.row}>
            <label className={ labelClass } htmlFor={name}>
                {label} {!isRequired ? '(optional)' : ''}
            </label>
            <select 
                className={styles.select} 
                id={name} 
                name={name} 
                required={isRequired}
                onChange={ e => setValue( e.target.value ) }
                onFocus={ e => setFocus( true ) }
                onBlur={ e => setFocus( false ) }
                >
                <option></option>
                {data.map( item => (
                    <option value={ item.value } key={ item.name }>{ item.name }</option>
                ))}
            </select>
        </div>  
    )
};


const TextArea = ({ label, name, rows = 10, isRequired }) => {
    const [ hasFocus, setFocus ] = useState( false );
    const [ value, setValue ] = useState( '' );
    const labelClass = hasFocus || value !== '' ? styles.labelFocus : styles.labelTextarea;

    return (
        <div className={styles.row}>
            <label className={ labelClass } htmlFor={name}>
                {label} {!isRequired ? '(optional)' : ''}
            </label>
            <textarea 
                className={styles.textarea} 
                id={name} 
                name={name}
                required={isRequired} 
                rows={rows}
                onChange={ e => setValue( e.target.value ) }
                onFocus={ e => setFocus( true ) }
                onBlur={ e => setFocus( false ) }
                value={ value }
            />
        </div>  
    )
};


const SubmitButton = ({ children }) => (
    <button className={styles.button} type="submit">
        {children}
    </button>
);


export { Form, InputField, SelectList, TextArea, SubmitButton }